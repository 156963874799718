import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/build/repo/src/templates/Docs.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Leveraging LLMs to improve intraspecies communication`}</h1>
    <p>{`1400s - the printing press was born`}</p>
    <p>{`1840 - the electrical telegraph enabled real time long distance communication`}</p>
    <p>{`1950s - english grew into the lingua franca`}</p>
    <p>{`1969 - arpanet enabled high bandwidth real time communication, spawning the internet`}</p>
    <p>{`2006 - google translate used statistical analysis`}</p>
    <p>{`2023 - the transformer enabled real time language translation`}</p>
    <p>{`Language is the most consequential technology our species has developed. As Yuval Noah Harrari well identified in Sapiens, our species' ability to communicate across distances, across time, and about the abstract (rather than the here/now) is the foundation of everything we as humans have accomplished.`}</p>
    <p>{`Language serves as the foundation of everything. Our technology (programming languages), science (mathematical symbology, periodic tables, nomenclature), our culture (memes, slang), our art (music, writing, poetry), our politics (laws, interpretation, translation of voting materials). It serves as a vehicle to codify and interpret our thoughts and represent our world models. And it's not just a secondary layer, it feeds both forward and backwards with our neural processes, shaping the way our brains develop.`}</p>
    <p>{`Our culture, survival, and collaboration strategies have been codified in language. And language has merged and evolved through migration and civilization change. Human history and culture is documented and preserved in the development of languages.`}</p>
    <p><strong parentName="p">{`GlobalEnglish and the internet's `}</strong></p>
    <p>{`My formative years between 5-10 were spent in Hong Kong. We moved there in 1997, shortly after the handover from UK to China. My native tongue and schooling was in English (of the British/Indian variety), I spoke Gujarati at home and when we spent summers in Bombay, we learned Madarin at school, and the majority of the Hong Kong population spoke a mix of Cantonese and English - and often code switched in the same sentence (a dilect referred to as Hong Kong-nese).`}</p>
    <p>{`My first exposure to technology was during the dot com boom, when my dad served as the CFO of AsiaContent - a hyper growth startup that localized US websites (like CNet and DoubleClick) into asian languages. It was the first Asian company to IPO on Nasdaq before liquidating 6 months later due to the pressures from venture shareholders to scale hiring far faster than revenue.`}</p>
    <p>{`We then moved to Silicon Valley where my dad served as CEO of GlobalEnglish - an online english learning company that raised $60million in venture with the goal of accelerating globalization and enabling fortune 500s to collaborate better across borders. It was there I did my first technology internship, helping design the company intranet for internal communications - which we shipped to a temperature controlled on-site server room - and designing EnglishCafe an online community for english learners.`}</p>
    <p>{`GlobalEnglish was named so because it identified as a new, business focused dialect of english that stood apart from British English, American English, Indian English, Spanglish, Hong Kong-nese, or any culture-specific dialect of english. GlobalEnglish was meant to be the connecting thread of the business world that operated cross border more than ever thanks to the advent of the internet and email. To allow deeper communication and collaboration amongst all societies in the world.`}</p>
    <p><strong parentName="p">{`Google Translate`}</strong></p>
    <p>{`My second internship took place at a small education startup Root-1 inc, under the mentorship of Vibhu Mittal. Vibhu was a professor of computational linguistics who joined Google as the first computer science researcher and employee 100. He hired Peter Norvig to Google and together built Google Translate using statistical machine translation methods leveraging English as a pivot node.`}</p>
    <p>{`Google's research team later improved the methods for computer translation that allowed the computer to learn it's own pivot node, developing it's own abstract representation of the meanings of words (embeddings) that were not grounded in english or any traditional notion of linguistics. This research led to the development of the Transformer, a step function improvement in statistical next token prediction for language models, which proved to translate between languages - given enough training data and cycles - far better than any linguistics based methods.`}</p>
    <p>{`Fast forward to 2023, the OpenAI team figured out a way to scale up transformers through an unprecedented amount of compute and training data to demonstrate that next token prediction could not just help humans translate between written languages, but abstract languages like computer code, mathematical notation, poetry, and much more.`}</p>
    <p>{`My curiosity with LLMs was peaked - along with the rest of the world - in spring 2023. I'd spent the past decade attempting to build a new university for applied computer science - focused on teaching students from a broad range of cultural backgrounds the language of computers, how to communicate/collaborate in business, and how to code switch when necessary. My burnout after the school closed led me to run away from technology/education into the world of music where - despite my best efforts - I kept finding myself associating with musicians who spent their days doing AI research.`}</p>
    <p>{`I wanted to better understand what ChatGPT really was, so I built a WhatsApp interface to OpenAI's chat API as a way to talk to it more easily. I used ChatGPT to help me write most of the code - in a most self referential way that Douglas Hofstadter would appreciate - and launched the bot to friends in the US and India. `}</p>
    <p><strong parentName="p">{`LLMs as a language innovation, not a technology innovation`}</strong></p>
    <p>{`The heaviest use of `}</p>
    <p>{`The AI safety obsession has perplexed me. We seem to be so focused on LLMs as a technology of warfare and abuse, that we seem to be overlooking the fundamental nature of this innovation.`}</p>
    <p><strong parentName="p">{`Music as a language`}</strong></p>
    <p>{`I've viewed my excursion into music as similar to Jobs' exposure to typography (that's where the similarities between Jobs)`}</p>
    <p><strong parentName="p">{`LLMs to preserve indigenous cultures`}</strong></p>
    <p>{`Better tools for human to human communication`}</p>
    <p>{`Personal experience of being global`}</p>
    <p>{`Art as a means to share stories`}</p>
    <p>{`Raja’s point that hearing is more direct to brain, skipping prefrontal cortex`}</p>
    <p>{`Thought as an abstraction layer above language`}</p>
    <p>{`Colonialism preferencing one language layer over another. Ramanujan story`}</p>
    <p>{`English as a connecting force, English as a dividing force`}</p>
    <p>{`LLMs as the new connective tissue`}</p>
    <p>{`A desire to be understood`}</p>
    <p>{`Escaping the binary`}</p>
    <p>{`Openness to new perspectives`}</p>
    <p>{`Humanism vs tribalism`}</p>
    <p>{`Collaboration as the thing that sets apart our species`}</p>
    <p>{`How merging cultures creates communication challenges`}</p>
    <p>{`The internet rewiring our species level connection across space and time`}</p>
    <p>{`Code switching`}</p>
    <p>{`LLMs as language innovation, not species innovation`}</p>
    <p>{`Apple, Google, Facebook`}</p>
    <p>{`Org design`}</p>
    <p>{`A company built for humanity, not shareholders`}</p>
    <p>{`What does that org look like`}</p>
    <p>{`A product vision, not a technology vision`}</p>
    <p>{`What is it that I want?`}</p>
    <p>{`Facebook / zoom are lower bandwidth`}</p>
    <p>{`Khan academy is higher bandwidth than lectures`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      