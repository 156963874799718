import React from 'react';
import PropTypes from 'prop-types';
import {Box, Typography} from '@material-ui/core';
import {Button} from 'gatsby-theme-material-ui';
import {useFinishPage} from 'data/queries/pages';
import {usePageContext} from 'data/page-context';

const NextPrev = ({next, gutterBottom, className, ...rest}) => {
  const finishPage = useFinishPage();
  const {
    page: {course},
  } = usePageContext();

  const nextPage = next ?? {title: 'Back to Dashboard', path: `${course.path}dashboard/`};
  return (
    <Box mx={6} mt={14}>
      <Box display="flex" marginBottom={gutterBottom ? 4 : 0} className={className} {...rest}>
        <Box flexGrow={1} px={1} pt={3} />
        <Box px={1} pt={1}>
          <Typography component="div" gutterBottom variant="body1">
            <Box fontSize={24} fontWeight={700}>
              {nextPage.title}
            </Box>
          </Typography>
        </Box>
      </Box>
      <Box display="flex" marginBottom={gutterBottom ? 4 : 0} className={className} {...rest}>
        <Box flexGrow={1} p={1} />
        <Box p={1}>
          <Button
            variant="contained"
            color="primary"
            to={nextPage.path}
            size="large"
            disableElevation
            onClick={finishPage}
          >
            Next Lesson
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

NextPrev.propTypes = {
  className: PropTypes.string,
  gutterBottom: PropTypes.bool,
  next: PropTypes.shape({
    title: PropTypes.string.isRequired,
    path: PropTypes.string.isRequired,
  }).isRequired,
};

export default NextPrev;
